var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-box-text-form"},[_c('div',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.requiredFlg)?_c('div',{staticClass:"required"},[_vm._v("必須")]):_vm._e()]),_c('div',{staticClass:"body"},[_c('validation-provider',{staticClass:"validate",attrs:{"rules":{
        requiredRule: _vm.requiredFlg,
        oneOfRule: _vm.selectData
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('base-select-box',{staticClass:"select-box",attrs:{"value":_vm.selectValue,"selectData":_vm.selectData,"disabled":_vm.selectDisabled,"styles":_vm.selectStyles},model:{value:(_vm.selectInput),callback:function ($$v) {_vm.selectInput=$$v},expression:"selectInput"}}),_c('div',{staticClass:"error",attrs:{"data-test":"select-error"}},_vm._l((errors),function(error,index){return _c('div',{key:index},[_vm._v(_vm._s(error))])}),0)]}}])}),_c('validation-provider',{staticClass:"validate",attrs:{"rules":Object.assign({}, _vm.validationRules,
        {requiredRule: _vm.requiredFlg,
        notSurrogatePair: true})},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('base-text-box',{attrs:{"value":_vm.textValue,"maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.textDisabled,"styles":_vm.textStyles,"type":'text'},model:{value:(_vm.textInput),callback:function ($$v) {_vm.textInput=$$v},expression:"textInput"}}),_c('div',{staticClass:"error",attrs:{"data-test":"text-error"}},_vm._l((errors),function(error,index){return _c('div',{key:index},[_vm._v(_vm._s(error))])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }